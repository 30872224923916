:root {
    /*Margins & Paddings*/
    --padding: 1%; 
    --margin: 0.5%; 
    --vertical-padding: 1rem; /* must NOT be a percentage value */
    --vertical-margin: 1rem; /* must NOT be a percentage value */
    /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 1100px;
    /*General*/
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
    --base-font-family: "Hind", sans-serif;
    --alternative-font-family: var(--base-font-family);
}

@import "/fontmodules/Lato.css";
@import "/fontmodules/Hind.css";

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.v1.1.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.2.css";
@import "/cssmodules/font-awesome.css";
@import "/cssmodules/magnific-popup.css";
/*@import "/cssmodules/animate.css";*/

/*------------------------------------------------------------------------------------------------ 
GENERAL ----------------------------------------------------------------------------------------*/

.textref {
    background: rgba(88, 110, 121, 0.34);
}


.tjanstitem {
    background: lightgrey;
}

body {
    font-family: "Hind", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    line-height: 1.5;
    font-weight: 300;
    color: #303030;
    background: url('/thumbs/1920x1080r/theme/intendit/graphics/mostrombg.jpg') center center/cover no-repeat fixed;
}
p {
    margin: 3px 0 5px;
}
a, a:hover {
    text-decoration: none;
    color: #303030;
}
img {
    max-width: 100%;
    height: auto;
    flex: none;
    width: 100%;
}
figure {
    margin: 0px;
}
.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

/* ------------------------------- FONTS + HEADINGS ---------------------------- */
h1, h2, h3, h4, h5, h6 {
    font-family: "Lato";
    margin: 0.5em 0;
    font-weight: 400;
}
.slick-title {
    font-size: 1.6rem;
    font-family: "Lato";
    font-weight: 600;
    text-align:center;
}


/* ------------- WRAPPERS ------------- */
.container.sml {
    max-width: 900px;
}
.container.med {
    max-width: 1300px;
}
.container.lrg {
    max-width: 1500px;
}
.container {
    width: 100%; /*fixar issue med bredd i vissa flexelement i IE*/
}
.wrapper {
    min-height: calc(100vh - 250px);
    position: relative;
    /*margin-top: 80px; För att flytta ner innehåll vid position:fixed på header*/
}

.content-wrapper {
    background: #fff;
    padding: 20px;
}

.referenser .content-wrapper {
    padding: 0px;
}

.extra {
    padding: 20px;    
}



.button.boka {
    display: block;
    margin: 1em 0;
    background: #e596ae;
    color: white;
    font-weight: 300;
    border: none;
    border-radius: 0px;
}

.button.tjanstknapp {
    margin: 1em 0;
    background: #154562;
    color: white;
    font-weight: 300;
    border: none;
    border-radius: 0px;

}

.kontakt-bild img {

}

/*------------------------------------------------------------------------------------------------ 
STARTSIDA ----------------------------------------------------------------------------------------*/

/* ---------------SLIDER----------- */


.slick {
    margin-bottom: 0;
    
}

.slick-track, .slick-substitute {
    display: flex;
    flex-direction: row;
    
} 

.slick-initialized .slick-slide, .slick-substitute > div {
    height: 100%;
    min-height: 50vh;
    width:100%;
    display: flex;
    background-size: cover;
    background-position: center center;
}



/*------------------------------------------------------------------------------------------------ 
CONTENT ----------------------------------------------------------------------------------------*/


/*------------------------------------------------------------------------------------------------ 
HEADER -----------------------------------------------------------------------------------------*/

/* ------------NAV + HEADER--------------- */
header {
    background: rgba(0, 0, 0, 0);
    z-index: 10;
    width: 100%;
    position: relative; /*om fixed el absolute, glöm ej margin-top på main*/
    top: 0;
    padding: 10px;
}

.logo {
    padding:30px 10px 30px 10px;
}
.logo img {
    max-height: 150px; 
    width: auto; 
}
.logo a, .logo img {
    display: block;
}

header nav {}
header nav ul.menu {}
header nav ul.menu li {
    padding: 10px 20px;
    margin: 0px;
}


header nav ul.menu .boka {
   background: #586e79;
}
header nav ul.menu li:nth-child(6) {
    background: #636;   
} 

header nav ul.menu li:nth-child(5) {
    background: #0075a9;   
} 

header nav ul.menu li:nth-child(4) {
        background: #e596ae;  

}
header nav ul.menu li:nth-child(3) {
        background: #154562;   

}
header nav ul.menu li:nth-child(2) {
    background: #e5b7ae;   
}
header nav ul.menu li:nth-child(1) {
    background: #663366;   
}



header nav ul.menu li a {
    font-size: 1.1em;
    letter-spacing: 4px;
    font-weight: 300;
    text-transform: uppercase;
    color: white;
}
.menu.menu li a:hover, .menu.menu li.active a {
    color: white;
}

/*
nav ul.menu li.dropdown > a:after { content: "<"; display: inline-block; margin: 0 0 0 5px; }
*/
/*------------------------------------------------------------------------------------------------ 
FOOTER & KONTAKT--------------------------------------------------------------------------------*/
/* -----------------FOOTER---------------- */
footer {
    background-color: #395049;
    padding: 30px 0;
}
footer, footer a, footer a:hover { color:#fff; }
footer svg {
    margin-right: 10px;
    fill: white;
}
footer h4 {margin-top:0;}

/* -----------------Intendit-footer---------------- */
.created-by span {
    background: #272b2a;
    text-align: center;
    color: #717171;
}
.created-by {
    background: #272b2a;
    text-align: center;
    padding: 10px 0; 
}
p.intenditfooter, p.intenditfooter a, p.intenditfooter a:hover  {
    color: #717171;
    line-height:1.1;
}
p.intenditfooter {
   margin:0;
}
/* -----------------KONTAKT---------------- */

/*------------------------------------------------------------------------------------------------ 
Nyheter & Nyhet--------------------------------------------------------------------------------*/
/* -----------------NYHETER---------------- */
.newsitem > div .button {
    background-color: #353575;
    color: white;
    border: 1px solid black;
}

.personalitem img {
    border-radius: 50%;
}

.imagetop {
    min-height: 45vh;
    background-size: cover;
    background-position: center top;
}

/* -----------------KONTAKTFORMULÄR---------------- */
.formwrapper {
    background-color: #afc9b5; 
    padding: 20px 0px;
}
.boltforms-row {
    float: left;
    width: 33.3%;
    padding-right: 10px;
    padding-left: 10px;
}

.boltforms-row:nth-child(n+4) {
    width: 100%;
    float: none;
} 
/*OBS BYT UT*/
input#contact_phone, input#contact_email, input#contact_name, textarea#contact_message {
    width: 100%;
}

.boltforms-row button {
    width: 100px;
    margin: 0 auto;
    display: block;
}

/* --------------- Google maps --------------------*/
/* Give map a height and it's images no max-width */
.map-canvas {
    height: 35vh;
}

.map-canvas img {
    max-width: none;
}


/*------------------------------------------------------------------------------------------------ 
MEDIA QUERIES ----------------------------------------------------------------------------------*/
/*----------------------------Allt under 1500px----------------------------*/
@media (max-width:1500px)
{
.container.lrg {
    width: 96%; 
    } 
}

@media (max-width:1300px)
{
    .container.med {
        width: 96%; 
    } 
}

/*----------------------------Allt under 1100px----------------------------*/
@media (max-width: var(--breakpoint-m))
{
   
    .container {
        width: 96%; 
    } 
    
    ul.menu.show li a {
        font-size: 0.9em;
    }
    ul.menu.show {
        background: #fff;
    }
  
}
/*----------------------------Allt under 800px----------------------------*/
@media (max-width: var(--breakpoint-s))
{
    /*Containers*/
    .container.sml {
        width: 96%; 
    } 

    /*Footer*/   
    footer .s-twelve {
        text-align: center; 
    }
    footer .s-twelve a {
        display:block; 
        text-align:center; 
        margin: 0 auto; 
    } 
  

}
/*---------------------------MEDIA XS - upp till 550-----------------------------*/
@media (max-width: var(--breakpoint-xs))
{ 
    body.homepage {
        background: url('/thumbs/1920x1080r/theme/intendit/graphics/mostrombg.jpg') center center/cover no-repeat fixed;
        background-position: 30% 65%;
        background-repeat: no-repeat;

    }
}



/*------------------------------------------------------Make FB widget responsive-------------------------------------------------------*/
.fb_iframe_widget, .fb_iframe_widget span, .fb_iframe_widget iframe[style], .twitter-timeline {width: 100% !important;}
